import request from '@/plugins/axios'
export function plan(data) {
  return request({
    url: '/plan',
    method: 'post',
    data
  })
}
export function planPage(page, limit) {
  return request({
    url: `/plan/${page}/${limit}`,
    method: 'post',
    data: {}
  })
}
export function putPlan(data) {
  return request({
    url: '/plan',
    method: 'put',
    data
  })
}
export function delPlan(id) {
  return request({
    url: `/plan/${id}`,
    method: 'delete'
  })
}

export function planDetail(id) {
  return request({
    url: `/plan/${id}`,
    method: 'get'
  })
}
