<template>
  <div class="public-sentiment">
    <div class="public-sentiment-head">
      <el-form :inline="true" ref="form">
        <el-form-item>
          <el-input
            clearable
            v-model.trim="searchForm.key"
            placeholder="请输入检索内容"
          ></el-input>
          <el-input
            v-model.trim="searchForm.websiteName"
            clearable
            placeholder="请输入网站名称"
          ></el-input>
          <el-date-picker
            v-model="searchForm.time"
            type="datetimerange"
            value-format="yyyy-MM-dd hh:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-input
            v-model.trim="searchForm.websiteName"
            style="width: 157px"
            clearable
            placeholder="请输入网站名称"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item>
          <el-date-picker
            v-model="searchForm.time"
            type="datetimerange"
            value-format="yyyy-MM-dd hh:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="searchArticlePage"
          >
            检索
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <p>
                【1】可以通过上面手动输入内容点击检索进行查询，也可以使用下方的关键词方案点击查询进行查询，亦可以使用日期范围加上方条件进行查询
              </p>
              <p>
                【2】点击下方标题可进入新闻详情页，详情页中可以点击“关注”，可以将新闻添加到“重点关注”中，以防想再看这个新闻的时候找不到
              </p>
            </div>
            <span slot="reference" class="head-info">
              <img src="@/assets/imgs/help.png" alt="" />
              <span>使用说明</span>
            </span>
          </el-popover>
        </el-form-item>
      </el-form>
      <div
        v-if="listRetrieval.length > 0"
        class="head-tag-box"
        :class="listRetrieval.length > 0 ? 'head-tag-box-margin' : ''"
      >
        <span>
          查询记录：
        </span>
        <span
          v-for="(item, i) in listRetrieval"
          :key="i"
          class="head-tag"
          @click.prevent="selectRecord(i, item)"
          :class="selectRecordIndex == i ? 'select-tag' : ''"
        >
          {{ item.searchKey }}
          <img
            class="close-log"
            :src="require('@/assets/imgs/i11.png')"
            alt=""
            @click.stop="closeLog(item)"
          />
        </span>
      </div>
    </div>
    <div
      class="public-sentiment-content public-sentiment-content1"
      :class="
        listRetrieval.length > 0
          ? 'public-sentiment-content1'
          : 'public-sentiment-content2'
      "
      v-loading="loading"
    >
      <div class="sentiment-left">
        <total-com
          style="margin-bottom:10px"
          :text="'共检索到数据' + total + '条'"
        />
        <div
          class="sentiment-left-content"
          v-infinite-scroll="getArticlePage"
          :infinite-scroll-disabled="scollDisabled"
          :infinite-scroll-distance="10"
          :infinite-scroll-immediate="true"
        >
          <div class="content-item" v-for="(item, i) in listData" :key="i">
            <div class="content-item-title">
              <span
                v-html="item.title"
                class="content-title"
                @click="lookNews(item)"
              ></span>

              <div class="item-info">
                <el-popover
                  placement="bottom"
                  trigger="hover"
                  popper-class="sentiment-popover"
                >
                  <div>
                    <div>
                      <img :src="require('@/assets/imgs/i8.png')" alt="" />
                      <span class="btn-1" @click="lookNews(item)">
                        查看新闻
                      </span>
                    </div>
                    <div v-if="!item.isRemark" style="margin-top:5px">
                      <img :src="require('@/assets/imgs/i10.png')" alt="" />
                      <span class="btn-1" @click="remarkItem(item, i)">
                        点击关注
                      </span>
                    </div>
                    <div v-else style="margin-top:5px">
                      <img :src="require('@/assets/imgs/i9.png')" alt="" />
                      <span class="btn-1" @click="clearRemarkItem(item, i)">
                        取消关注
                      </span>
                    </div>
                  </div>
                  <span slot="reference" class="head-info">
                    <img :src="require('@/assets/imgs/i7.png')" alt="" />
                  </span>
                </el-popover>
              </div>
            </div>
            <div class="content-item-time">
              {{ item.issuingTime }} {{ item.author }}
            </div>
            <div class="content-item-text" v-html="item.text"></div>
            <div class="content-item-bottom">
              <img v-if="item.ico" :src="item.ico" alt="" />
              <span class="item-bottom-span1">{{ item.websiteName }}</span>
              <span class="item-bottom-span2">
                来源：
                <a :href="item.url" target="_blank">{{ item.url }}</a>
              </span>
            </div>
          </div>
          <li v-if="nomoreData && listData.length > 0">加载中...</li>
          <li v-else-if="!nomoreData && listData.length > 0">没有更多了...</li>
        </div>
        <no-data v-if="listData.length == 0" />
        <!-- <pagination
          :total="total"
          :page-size="limit"
          :currentPage="current"
          :pagerCount="5"
          background
          :sizesArr="[15, 30, 60, 200]"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        /> -->
      </div>
      <div class="sentiment-right">
        <div class="sentiment-right-title">关键词方案</div>
        <div class="sentiment-right-title-border"></div>
        <div class="sentiment-right-record">
          <ul>
            <!-- <li v-for="(item, i) in listRetrieval" :key="i">
              <span
                @click.prevent="selectRecord(i, item)"
                class="history-words"
                :class="selectRecordIndex == i ? 'select-obj' : ''"
              >
                {{ item.searchKey }}
                <img
                  class="close-log"
                  :src="require('@/assets/imgs/i11.png')"
                  alt=""
                  @click.stop="closeLog(item)"
                />
              </span>
            </li> -->
            <li v-for="(item, i) in planList" :key="i">
              <span
                class="history-words"
                @click="selectTag(item)"
                :class="tagIndexObj.id == item.id ? 'select-obj' : ''"
              >
                {{ item.groupName }}
              </span>
            </li>
          </ul>
          <no-data v-if="planList.length == 0" />
        </div>
      </div>
    </div>
    <!--  -->

    <!-- 弹框 -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="577px">
      <div>
        <el-form :inline="true" ref="form" label-width="100px">
          <el-form-item label="分组名称">
            <el-input
              style="width: 370px"
              v-model.trim="submitForm.a"
              placeholder="组名"
            ></el-input>
          </el-form-item>
          <el-form-item label="关键词">
            <el-input
              style="width: 370px"
              v-model.trim="submitForm.b"
              type="textarea"
              :rows="1"
              placeholder="关键词 "
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  articlePage,
  articleRemark,
  clearArticleRemark,
  logSearch,
  deleteSearchLog
} from '@/api/sentiment.js'
import { planPage } from '@/api/keywords.js'

export default {
  data() {
    return {
      loading: false,
      planList: [],
      searchForm: {
        key: '',
        websiteName: '',
        time: []
      },
      copySearchForm: {},
      // 当前页
      current: 1,
      // 每页记录数
      limit: 30,
      // 总记录数
      total: 0,
      dialogVisible: false,
      submitForm: {},
      tagIndexObj: {},
      listData: [],
      selectRecordIndex: null,
      listRetrieval: [], //检索记录
      scollDisabled: false,
      nomoreData: true
    }
  },

  created() {
    this.getPlan()
    // this.getArticlePage()
    this.getlogSearch()
  },
  methods: {
    // 检索记录
    getlogSearch() {
      logSearch().then(res => {
        this.listRetrieval = res
      })
    },
    // 删除检索记录
    closeLog(row) {
      deleteSearchLog(row.id).then(res => {
        this.$message({
          type: 'success',
          message: '已删除!'
        })
        this.selectRecordIndex = null
        this.getlogSearch()
      })
    },
    // 点击关注
    remarkItem(row, index) {
      articleRemark(row.id).then(res => {
        this.$message({
          type: 'success',
          message: '关注成功!'
        })
        this.listData[index].isRemark = true
        // this.getArticlePage()
      })
    },
    // 取消关注
    clearRemarkItem(row, index) {
      clearArticleRemark(row.id).then(res => {
        this.$message({
          type: 'success',
          message: '取消关注!'
        })
        this.listData[index].isRemark = false
        // this.getArticlePage()
      })
    },
    // 查看新闻
    lookNews(row) {
      this.$router.push({
        path: '/sentiment/detail/' + row.id,
        query: {
          newsTitle: row.title
        }
      })
    },
    searchArticlePage() {
      this.current = 1
      this.listData = []
      this.selectRecordIndex = null
      this.copySearchForm = Object.assign({}, this.searchForm)
      this.getArticlePage()
      this.getlogSearch()
    },
    getArticlePage() {
      return new Promise((resolve, reject) => {
        this.scollDisabled = true
        this.loading = true
        let obj = {
          beginDate:
            Array.isArray(this.copySearchForm.time) &&
            this.copySearchForm.time.length > 0
              ? this.copySearchForm.time[0]
              : '',
          endDate:
            Array.isArray(this.copySearchForm.time) &&
            this.copySearchForm.time.length > 0
              ? this.copySearchForm.time[1]
              : '',
          id: this.tagIndexObj.id ? this.tagIndexObj.id : '',
          key: this.copySearchForm.key,
          websiteName: this.copySearchForm.websiteName
        }
        articlePage(this.current, this.limit, obj).then(res => {
          this.total = res.all
          if (res.items.length > 0) {
            this.current++
            this.scollDisabled = false
            this.listData = this.listData.concat(res.items)
            if (res.items.length < this.limit) {
              this.nomoreData = false
            }
          } else {
            this.nomoreData = false
          }
          this.loading = false
          resolve()
        })
      })
    },
    // 关键词数据
    getPlan() {
      planPage(1, 1000).then(data => {
        //
        this.planList = data.items
      })
    },
    // 编辑
    update(i) {
      this.dialogVisible = true
    },
    currentChange(val) {
      this.current = val
      this.getArticlePage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getArticlePage()
    },
    //
    selectTag(row) {
      if (row.id == this.tagIndexObj.id) {
        this.tagIndexObj = {}
      } else {
        this.tagIndexObj = Object.assign({}, row)
      }
      this.current = 1
      this.listData = []
      this.getArticlePage()
    },

    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (typeof obj[i] === 'string') {
          if (obj[i] && obj[i].trim() !== '') {
            arr.push(obj[i])
          }
        } else if (Array.isArray(obj[i])) {
          if (obj[i].length > 0) {
            arr = obj[i]
          }
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    },
    selectRecord(i, row) {
      this.selectRecordIndex = i
      let obj = {
        beginDate: '',
        endDate: '',
        id: '',
        key: row.searchKey,
        websiteName: ''
      }
      this.copySearchForm = Object.assign({}, obj)
      this.current = 1
      this.listData = []
      this.getArticlePage()
    }
  }
}
</script>

<style lang="scss">
.public-sentiment {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  .public-sentiment-head {
    width: 100%;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .el-form-item {
      margin: 10px 0;
      padding: 0 15px;
      .el-input {
        margin-right: 10px;
        width: 220px;
      }
    }
    .head-info {
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
        vertical-align: middle;
      }
      span {
        font-size: 12px;
        font-weight: 300;
        color: #4b4b4b;
      }
    }
    .head-tag-box-margin {
      margin: 10px 0 15px 15px;
    }
    .head-tag-box {
      .head-tag {
        padding: 7px 13px;
        background: rgb(241, 241, 241);
        border: 0px solid #000000;
        border-radius: 15px;
        border: 1px solid rgb(224, 224, 224);
        margin-right: 15px;
        user-select: none;
        cursor: pointer;
        position: relative;
        .close-log {
          position: absolute;
          top: -5px;
          right: -5px;
          display: none;
        }
        &:hover {
          .close-log {
            display: block;
          }
        }
      }
      .select-tag {
        color: white;
        background: #131212;
        border: none;
      }
    }
  }
  .public-sentiment-content1 {
    height: calc(100% - 115px);
  }
  .public-sentiment-content2 {
    height: calc(100% - 70px);
  }
  .public-sentiment-content {
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .sentiment-left {
      width: calc(100% - 270px);
      height: 100%;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding: 14px;
      padding-top: 0;
      position: relative;
      .sentiment-left-content {
        width: 100%;
        height: calc(100% - 36px);
        overflow: hidden;
        overflow-y: auto;
        .content-item {
          width: 100%;
          border-bottom: 1px solid #e7e7e7;
          padding-bottom: 15px;
          margin-bottom: 20px;
          overflow: hidden;
          .content-item-title {
            font-size: 18px;
            font-weight: bold;
            color: #1a1a1b;
            position: relative;
            .content-title {
              cursor: pointer;
              &:hover {
                color: #0582e7;
              }
            }
            .item-info {
              position: absolute;
              right: 20px;
              top: 0px;
              img {
                width: 20px;
                height: 20px;
                object-fit: cover;
              }
            }
          }
          .content-item-time {
            font-size: 12px;
            font-weight: 300;
            color: #666666;
            margin: 15px 0;
          }
          .content-item-text {
            font-size: 14px;
            font-weight: 300;
            color: #444444;
            margin: 15px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .content-item-bottom {
            img {
              // width: 30px;
              height: 30px;
              object-fit: cover;
              vertical-align: middle;
              border-radius: 5px;
              margin-right: 10px;
            }
            .item-bottom-span1 {
              font-size: 14px;
              margin-right: 15px;
              font-weight: 300;
              color: #666666;
            }
            .item-bottom-span2 {
              font-size: 12px;
              font-weight: 300;
              color: #1a1a1b;
            }
          }
        }
      }
      li {
        text-align: center;
        font-size: 12px;
        color: #9c9797;
        list-style: none;
      }
    }
    .sentiment-right {
      width: 260px;
      height: 100%;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding: 14px;
      .sentiment-right-title {
        padding-left: 10px;
        border-left: 4px solid #694638;
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
      }
      .sentiment-right-title-border {
        border-bottom: 1px solid #e5e5e5ce;
        margin: 13px 0;
      }
      .sentiment-right-record {
        height: calc(100% - 40px);
        overflow: hidden;
        overflow-y: auto;
        // background: #2a2a2a;
        padding-top: 10px;
        position: relative;
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            margin-bottom: 15px;
            .history-words {
              padding: 5px 10px;
              background: #efefef;
              border: 0px solid #000000;
              border-radius: 20px;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 300;
              color: #2a2a2a;
              cursor: pointer;
              user-select: none;
            }
            .select-obj {
              background: #292828;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  //
}
.keywords-popover {
  width: 80px !important;
  min-width: 50px !important;
  padding: 5px;
}
.sentiment-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
</style>
